<template lang="pug">
v-row
  v-col(cols="12")
    v-card(color="grey lighten-5")
      v-card-text
        v-row
          v-col(v-for="(kit, i) in kits" :key="i")
            v-card
              v-card-text
                v-select(v-model="kit.model" :label="$t('label.model')" :items="models" item-text="name" item-value="model")
                v-row
                  v-col
                    v-text-field(v-model="kit.segment[0]" label="début" type="datetime-local")
                  v-col
                    v-text-field(v-model="kit.segment[1]" label="fin" type="datetime-local")
                  v-col.text-right(cols="12")
                    v-btn(x-small icon @click="kits.splice(i,1)")
                      v-icon(color="red")
                        | mdi-delete
            v-divider
          v-col.text-right(cols="12")
            v-btn.mr-6(color="primary" small @click="setAsDefault()")
              | {{ $t("btn.setAsDefault") }}
            v-btn.mr-6(color="primary" small @click="addKit()")
              v-icon(small)
                | mdi mdi-plus
            v-btn(v-if="model_generated" color="primary" small @click="applyToAllPoint()")
              v-icon(small)
                | mdi-play
</template>

<script>
import api from '@/api'
import Highcharts from 'highcharts'
import stockInit from 'highcharts/modules/stock'
stockInit(Highcharts)
import moment from 'moment-timezone'
import _ from 'lodash'

import EventBus from '@/plugins/event-bus'

export default {
  props: ['point', 'castzone'],
  data() { 
    return {
      models: [],
      model_generated: [],
      kits: [
        {
          model: null,
          duration: '0',
          segment: [0, 120],
        },
      ],
    }
  },
  watch: {
    map_kits: {
      deep: true,
      async handler() {
        console.log('change ??')
      },
    },
  },
  async mounted() {
    await this.getModels()
    await this.setModel()
    this.applyToAllPoint()
  },
  methods: {
    async getModels() {
      let models = []
      let runs = await api.atmocast.get_models(this.$route.params.id)
      if (runs) {
        runs.atmocasts.forEach((element) => {
          element.name = `${element.model} ${moment(element.time * 1000).format(
            'DD/MM/YYYY HH:mm'
          )}`
          models.push(element)
        })
        runs.publicasts = _.orderBy(runs.publicasts, 'id', 'desc')
        runs.publicasts.forEach((element, index) => {
          if (index === 0) {
            element.name = `Dernière publication (${moment(
              element.time_min * 1000
            ).format('DD/MM/YYYY HH:mm')})`
            element.model = 'publicast-last'
          } else {
            element.name = `Avant dernière publication (${moment(
              element.time_min * 1000
            ).format('DD/MM/YYYY HH:mm')})`
            element.model = 'publicast-before-last'
          }
          models.push(element)
        })
        this.models = models
      }
    },
    async setAsDefault() {
      let primaryModel = this.kits[0].model
      let secondaryModel
      if (this.kits[1]) {
        secondaryModel = this.kits[1].model
      }
      let data = {
        model_short: primaryModel,
        model_mid: primaryModel,
      }
      if (secondaryModel) {
        data.model_mid = secondaryModel
      }
      try {
        await api.models.update_schema(this.$route.params.id, data)
      } catch (err) {
        this.error = true
      }
    },
    setModel() {
      let selectedModelPrimary = this.models.find(
        (map) => map.model == this.castzone.models.model_short
      )
      let selectedModelSecondary = this.models.find(
        (map) => map.model == this.castzone.models.model_mid
      )
      this.kits = []
      if (selectedModelPrimary) {
        console.log('selectedModelPrimary', selectedModelPrimary)
        this.addKit(selectedModelPrimary.model, [
          moment(selectedModelPrimary.time*1000).format('YYYY-MM-DD[T]HH:mm'),
          moment(selectedModelPrimary.time_max*1000).format('YYYY-MM-DD[T]HH:mm'),
        ])
      }
      if (selectedModelSecondary) {
        this.addKit(selectedModelSecondary.model, [
          moment(selectedModelPrimary.time_max*1000).format('YYYY-MM-DD[T]HH:mm'),
          moment(selectedModelSecondary.time_max*1000).format('YYYY-MM-DD[T]HH:mm'),
        ])
      }
      // this.applyToAllPoint();
    },
    addKit(model = null, segment = [moment().format('YYYY-MM-DD[T]HH:mm'), moment().add(72, 'hours').format('YYYY-MM-DD[T]HH:mm')]) {
      if (this.kits.length <= 2) {
        this.kits.push({
          model: model,
          duration: '0',
          max: segment[1],
          segment: segment,
        })
      }
    },
    applyToAllPoint() {
      var kits = this.kits
      EventBus.$emit('applyToAll', kits)
      // this.$store.dispatch("setKits", kits);
    },
  },
}
</script>

<style>
</style>